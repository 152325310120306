<template>
    <div>
        <CModalExtended
            size="lg"
            color="dark"
            :closeOnBackdrop="false"
            :title='tituloModal'
            :show.sync="$store.state.muelle.modalMuelle"
        >
            <CRow>
            <CCol sm="12">
            <CCard class="card-simple">
            <CCardBody>    
                <CRow>
                    <CCol sm="4">
                        <CRow>
                            <CCol sm="12">
                                <div class="preview">    
                                    <picture-input
                                        ref="imageInput"
                                        :imgSrc="formMuelle.Image_url"
                                        width="200"
                                        height="200"
                                        accept="image/jpeg,image/png,image"
                                        size="10"
                                        :custom-strings="imgInputTexts"
                                        @change="handleFileUpload"
                                        v-if="$store.state.muelle.modalMuelle"
                                    >
                                    </picture-input>
                                </div>
                                <div v-if="$v.formMuelle.RutaMuelle.$error">
                                    <div class='text-invalid-feedback' v-if="!$v.formMuelle.RutaMuelle.isValidFile">{{$t('label.badImage')}}</div>
                                </div>
                            </CCol>
                        </CRow>
                    </CCol>
                    <CCol sm="8">
                        <CRow>
                            <CCol sm="12">
                                <CInput
                                :label="$t('label.name')"
                                addLabelClasses="required text-right"
                                v-uppercase
                                :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                                size="sm"
                                :placeholder="$t('label.berthName')"
                                v-model="$v.formMuelle.NbMuelle.$model"
                                :is-valid="hasError($v.formMuelle.NbMuelle)"
                                :invalid-feedback="errorMessage($v.formMuelle.NbMuelle)"
                                @blur="$v.formMuelle.NbMuelle.$touch()"
                                required 
                                >
                                <!--    <template #invalid-feedback>
                                        <div v-if="$v.formMuelle.NbMuelle.$error">
                                            <div class='text-danger' v-if="!$v.formMuelle.NbMuelle.required">{{$t('label.required')}}</div>
                                            <div class='text-danger' v-if="!$v.formMuelle.NbMuelle.onlyAlphanumeric">{{$t('label.onlyAlphanumeric')}}</div>
                                            <div class='text-danger' v-if="!$v.formMuelle.NbMuelle.minLength">{{$t('label.min')+$v.formMuelle.NbMuelle.$params.minLength.min+$t('label.chars')}}</div>
                                            <div class='text-danger' v-if="!$v.formMuelle.NbMuelle.maxLength">{{$t('label.max')+$v.formMuelle.NbMuelle.$params.maxLength.max+$t('label.chars')}}</div>
                                        </div>
                                    </template>-->
                                </CInput>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol sm="12">
                                <div class="form-group form-row" rol="group">
                                    <label class="col-sm-4 col-form-label col-form-label-sm required text-right" 
                                    for="labelPuerto"> {{$t('label.port')}}</label>
                                    <div class="col-sm-8 input-group-sm">
                                        <v-select
                                            @blur="$v.formMuelle.IdPuerto.$touch()"
                                            v-model="$v.formMuelle.IdPuerto.$model" 
                                            :options="optionsDataPuertos" :reduce="option => option.value" 
                                            :filter="fuseSearch" 
                                            :getOptionLabel="option => option.title"
                                            :class="isPortValid(dockVal)"
                                        >
                                            <template #no-options="{ }">
                                                {{ $t('label.noSearchData') }}
                                            </template>
                                            <template #option="{ Json, title }">
                                                {{ title }}
                                                <br />
                                                <cite>{{ Json.CodIsoAlpha3 }} - {{ Json.NbPais }} </cite>
                                            </template>
                                        </v-select>
                                        <div class="col-sm-12 px-0" v-if="$v.formMuelle.IdPuerto.$error">
                                            <div class='text-invalid-feedback' v-if="!$v.formMuelle.IdPuerto.required">{{$t('label.required')}}</div>
                                        </div>
                                    </div>
                                </div>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol sm="12">
                                <CInput
                                    :label="$t('label.condition')"
                                    :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                                    size="sm"
                                    v-uppercase
                                    addLabelClasses="text-right"
                                    :placeholder="$t('label.condition')+$t('label.berth')"
                                    @blur="$v.formMuelle.CondicionMuelle.$touch()"
                                    v-model="$v.formMuelle.CondicionMuelle.$model"
                                    :is-valid="hasError($v.formMuelle.CondicionMuelle)"
                                    :invalid-feedback="errorMessage($v.formMuelle.CondicionMuelle)"
                                    required 
                                >
                                <!--    <template #invalid-feedback>
                                        <div v-if="$v.formMuelle.CondicionMuelle.$error">
                                            <div class='text-danger' v-if="!$v.formMuelle.CondicionMuelle.minLength">{{$t('label.min')+$v.formMuelle.CondicionMuelle.$params.minLength.min+$t('label.chars')}}</div>
                                            <div class='text-danger' v-if="!$v.formMuelle.CondicionMuelle.maxLength">{{$t('label.max')+$v.formMuelle.CondicionMuelle.$params.maxLength.max+$t('label.chars')}}</div>
                                        </div>
                                    </template>-->
                                </CInput>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol sm="12">
                                <CInput
                                    :label="$t('label.berthLine')+' (MTR)'"
                                    addLabelClasses="required text-right"
                                    :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                                    size="sm"
                                    v-model="$v.formMuelle.LineaAtraque.$model"
                                    type="number"
                                    step=".01"
                                    :is-valid="hasError($v.formMuelle.LineaAtraque)"
                                    :invalid-feedback="errorMessage($v.formMuelle.LineaAtraque)"
                                    @blur="$v.formMuelle.LineaAtraque.$touch()"
                                >
                                <!--    <template #invalid-feedback v-if="$v.formMuelle.LineaAtraque.$error">
                                        <div class='text-danger' v-if="!$v.formMuelle.LineaAtraque.required">{{$t('label.required')}}</div>
                                        <div class='text-danger' v-if="!$v.formMuelle.LineaAtraque.decimal">{{$t('label.onlyDecimal')}}</div>
                                        <div class='text-danger' v-if="!$v.formMuelle.LineaAtraque.minValue">{{$t('label.min')+$v.formMuelle.LineaAtraque.$params.minValue.min}} </div>
                                        <div class='text-danger' v-if="!$v.formMuelle.LineaAtraque.maxValue">{{$t('label.max')+$v.formMuelle.LineaAtraque.$params.maxValue.max}} </div>
                                    </template>-->
                                </CInput>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol sm="12">
                                <CInput
                                    :label="$t('label.draft')+' (MTR)'"
                                    addLabelClasses="required text-right"
                                    v-model="$v.formMuelle.Calado.$model"
                                    type="number"
                                    :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                                    size="sm"
                                    step=".01"
                                    :is-valid="hasError($v.formMuelle.Calado)"
                                    :invalid-feedback="errorMessage($v.formMuelle.Calado)"
                                    @blur="$v.formMuelle.Calado.$touch()"
                                >
                                <!--    <template #invalid-feedback v-if="$v.formMuelle.Calado.$error">
                                        <div class='text-danger' v-if="!$v.formMuelle.Calado.required">{{$t('label.required')}}</div>
                                        <div class='text-danger' v-if="!$v.formMuelle.Calado.decimal">{{$t('label.onlyDecimal')}}</div>
                                        <div class='text-danger' v-if="!$v.formMuelle.Calado.minValue">{{$t('label.min')+$v.formMuelle.Calado.$params.minValue.min}} </div>
                                        <div class='text-danger' v-if="!$v.formMuelle.Calado.maxValue">{{$t('label.max')+$v.formMuelle.Calado.$params.maxValue.max}} </div>
                                    </template> -->
                                </CInput>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol sm="12">
                                <CInput
                                    :label="$t('label.mooring')"
                                    :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                                    addLabelClasses="text-right"
                                    size="sm"
                                    v-model="$v.formMuelle.Vita.$model"
                                    type="number"
                                    step=".01"
                                    :is-valid="hasError($v.formMuelle.Vita)"
                                    :invalid-feedback="errorMessage($v.formMuelle.Vita)"
                                    @blur="$v.formMuelle.Vita.$touch()"
                                >
                                 <!--    <template #invalid-feedback v-if="$v.formMuelle.Vita.$error">
                                        <div class='text-danger' v-if="!$v.formMuelle.Vita.decimal">{{$t('label.onlyDecimal')}}</div>
                                        <div class='text-danger' v-if="!$v.formMuelle.Vita.minValue">{{$t('label.min')+$v.formMuelle.Vita.$params.minValue.min}} </div>
                                        <div class='text-danger' v-if="!$v.formMuelle.Vita.maxValue">{{$t('label.max')+$v.formMuelle.Vita.$params.maxValue.max}} </div>
                                    </template>  -->
                                </CInput>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol sm="12">
                                <CSelect
                                    :label="$t('label.operative')"
                                    addLabelClasses="text-right"
                                    :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                                    size="sm"
                                    v-model="formMuelle.FgOperativo"
                                    :value.sync="formMuelle.FgOperativo"
                                    :options="selectOptionsOperativo"
                                    @blur="$v.formMuelle.FgOperativo.$touch()"
                                    :is-valid="hasError($v.formMuelle.FgOperativo)"
                                    :invalid-feedback="errorMessage($v.formMuelle.FgOperativo)"
                                >
                                 <!--    <template #invalid-feedback>
                                        <div v-if="$v.formMuelle.FgOperativo.$error">
                                            <div class='text-danger' v-if="!$v.formMuelle.FgOperativo.required">{{$t('label.required')}}</div>
                                        </div>
                                    </template>-->
                                </CSelect>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol sm="12">
                                <CSelect
                                    :label="this.$t('label.status')"
                                    addLabelClasses="required text-right"
                                    :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                                    size="sm"
                                    :is-valid="formMuelle.FgActMuelle"
                                    v-if="!idIndentification"
                                    v-model="formMuelle.FgActMuelle"
                                    :value.sync="formMuelle.FgActMuelle"
                                    :options="selectOptions"
                                />
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>
                <CElementCover 
                    :opacity="0.8"
                    v-if="apiStateLoading"
                >
                    <label class="d-inline">{{$t('label.load')}}... </label>
                    <CSpinner size="sm" />
                </CElementCover>
            </CCardBody>
            </CCard>
            </CCol>
            </CRow>
            <div slot="footer">
                <CButton
                    color="add"
                    class="m-2"
                    @click="registerData"
                    :disabled="apiStateLoading"
                >
                    <div v-if="apiStateLoading">
                        <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                        {{$t('button.accept')}}
                    </div>

                    <div v-if="!apiStateLoading">
                        <CIcon name="checkAlt"/>&nbsp;
                        {{$t('button.accept')}}
                    </div>
                </CButton>
                <CButton
                    color="wipe"
                    @click="cerrarModal"
                    class="m-2"
                    :disabled="apiStateLoading"
                >
                <CIcon name="x"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
            </div>
        </CModalExtended>
    </div>
</template>

<script>
    import Fuse from "fuse.js";
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import  FormMuelle  from '@/_validations/muelle/MuelleValidation';
    import UpperCase  from '@/_validations/uppercase-directive';
    import TipoGruaMixin from '@/_mixins/tipo-grua';
    import GeneralMixin from '@/_mixins/general';
    import ModalMixin from '@/_mixins/modal';
    import { GenerateImgPlaceholder } from '@/_helpers/funciones';
    import PictureInput from '@/components/picture-input';
    //data
    function data() {
        return {
            formMuelle:{
                Idmuelle:0,
                IdPuerto:null,
                NbMuelle:'',
                CondicionMuelle:'',
                LineaAtraque:0,
                Calado:0,
                Vita:0,
                FgOperativo:true,
                RutaMuelle:'',
                FgActMuelle:true,
                Image_url:'/img/uploadPhotoEs.svg',
            },
            Image_original:null,
            srcError:'/img/errorimage.jpg',
            originalAct:'',
            paisSelected:'',
            loading:false,
            dataListPaises:[],
            dockVal: false,
            tituloModal: '',
        }
    }
    //methods
    function cambio(e){
        this.formMuelle.FgActMuelle = !this.formMuelle.FgActMuelle;
    }
    function cerrarModal(){
       // this.tituloModal= '';
        if(this.apiStateLoading || this.loading)
            return
        this.$store.commit('muelle/mutationModal', false);
    }
    function toKebabCase (str) {
      return str.replace(/([a-z])([A-Z0-9])/g, '$1-$2').toLowerCase()
    }
    function registerData(){
        this.$v.formMuelle.$touch();
        if (this.$v.formMuelle.$pending || this.$v.formMuelle.$error){
            this.dockVal = true;
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.errorsPleaseCheck'),
                type: "error"
            });
        } 
        if (this.$v.formMuelle.$pending || this.$v.formMuelle.$error) return;

        let { Idmuelle,IdPuerto,NbMuelle,CondicionMuelle,LineaAtraque,Calado,Vita,FgOperativo,RutaMuelle,FgActMuelle } = this.formMuelle;
        const { dispatch } = this.$store;
        const Image_original = this.Image_original;
        //var source = 'https://www.google.es/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png';
        
        if(this.originalAct !== '' && Idmuelle !== 0){
            if(this.originalAct !== FgActMuelle){
                this.$swal.fire({
                    text: `${this.$t('label.changeStatusQuestion')+' '} ${NbMuelle}?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#42AA91',
                    cancelButtonColor: '#E1373F',
                    confirmButtonText: this.$t('button.confirm'),
                    cancelButtonText: this.$t('button.cancel')
                }).then((result) => {
                    if (result.isConfirmed) {
                        dispatch('muelle/postregisterMuelle', { Idmuelle,IdPuerto,NbMuelle,CondicionMuelle,LineaAtraque,Calado,Vita,FgOperativo,RutaMuelle,FgActMuelle,Image_original });
                    }else {
						//this.$swal(this.$t('label.operationCancelled'));
					}
                })
            }else{
                dispatch('muelle/postregisterMuelle', { Idmuelle,IdPuerto,NbMuelle,CondicionMuelle,LineaAtraque,Calado,Vita,FgOperativo,RutaMuelle,FgActMuelle,Image_original });
            }
        }else{
            dispatch('muelle/postregisterMuelle', { Idmuelle,IdPuerto,NbMuelle,CondicionMuelle,LineaAtraque,Calado,Vita,FgOperativo,RutaMuelle,FgActMuelle,Image_original });
        }

        //
    }
    function handleFileUpload(files, e) {
        this.formMuelle.RutaMuelle = this.$refs.imageInput.file;
        this.$v.formMuelle.RutaMuelle.$touch();     
    }
    function fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["title", "Json.NbPais", "Json.CodIsoAlpha3"],
        shouldSort: true
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    }

    function isPortValid(dockVal) {
    if(this.dockVal === true){ 
        return (this.formMuelle.IdPuerto == null || this.formMuelle.IdPuerto == "") ? 'no-valido' : 'valido';
    }
    }

    //mounted
    function mounted() {
        //this.$store.dispatch('ubicacion/getPaislist');
    }

    //computed
    function idIndentification(){
        return this.formMuelle.Idmuelle === 0;
    }
    function apiStateLoading() {
        return this.apiState === ENUM.LOADING;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING || this.apiStateForm === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    function optionsListPuertos(){
        if(this.myDataPuerto.length === 0){
            return [{
                title: this.$t('label.select'),
                value: null,
                Json: {
                    NbPais: '',
                    CodIsoAlpha3: ''
                },
            }];
        }else{
            const chart = [{
                title: this.$t('label.select'),
                value: null,
                Json: {
                    NbPais: '',
                    CodIsoAlpha3: ''
                },
            }];

            try{
                const datos = this.myDataPuerto;
                for (let index = 0; index < datos.length; index++) {
                    const element = datos[index];
                    chart.push({
                        title: element.PortName,
                        value: element.PortId,
                        Json: {
                            NbPais:element.CountryJson[0].CountryName,
                            CodIsoAlpha3: element.CountryJson[0].IsoCodeAlpha3
                        },
                    })
                }
                return chart;
            }catch(error){
                return chart;
            }
        }
    }
    function modalMuelle(newQuestion,Oldquestion){
        if(newQuestion === false){
            this.formMuelle.Idmuelle=0;
            this.formMuelle.NbMuelle='';
            this.formMuelle.CondicionMuelle='';
            this.formMuelle.IdPuerto=null;
            this.formMuelle.LineaAtraque=0;
            this.formMuelle.Calado=0;
            this.formMuelle.Vita=0;
            this.formMuelle.FgOperativo=true;
            this.formMuelle.RutaMuelle='';
            this.formMuelle.FgActMuelle=true;
            this.Image_original = null;
            this.dockVal = false;
            this.formMuelle.Image_url='/img/uploadPhotoEs.svg';
            this.tituloModal= '';
            this.$nextTick(() => { this.$v.$reset() })
            this.$store.commit('muelle/asignarid', 0);
        }else{
            if(this.idState === 0){
                const idPuerto = this.$store.state.muelle.filtroPuerto;
                if(idPuerto !== null && idPuerto !== '' && idPuerto !== undefined){
                    this.formMuelle.IdPuerto=idPuerto;
                }
                let _lang = this.$i18n.locale;
                this.formMuelle.Image_url = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
            }
            
            this.tituloModal = this.$t('label.nuevo')+this.$t('label.berth');
            //this.$store.dispatch('muelle/getPuertolist');
        }
    }
    function idState(newQuestion,Oldquestion){
        if(this.modalMuelle){
            if(newQuestion !== 0){
                const icon = this.BerthRoute;
                if(icon != null && icon != ''){
                    this.formMuelle.Image_url = `${this.$store.getters["connection/getBase"]}${icon.replace('Public/', '', null,'i')}`;
                }else{
                    let _lang = this.$i18n.locale;
                    this.formMuelle.Image_url = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
                }

                this.tituloModal='';
                this.$store.state.muelle.apiStateForm = ENUM.LOADING;
                try {
                    this.$http.ejecutar('GET', 'Berth-by-id',{ BerthId: newQuestion }, '' ).then(response => {
                        if(response.status === 200){
                            const Information = response.data.data;
                            if(Information.length !== 0){
                                this.tituloModal = this.$t('label.edit')+this.$t('label.berth')+": "+Information[0].BerthName;
                                this.formMuelle.Idmuelle= newQuestion;
                                this.formMuelle.NbMuelle= Information[0].BerthName;
                                this.formMuelle.CondicionMuelle=Information[0].BerthCondition;
                                this.formMuelle.IdPuerto=Information[0].PortId;
                                this.formMuelle.LineaAtraque=Information[0].LineDock;
                                this.formMuelle.Calado=Information[0].Draft;
                                this.formMuelle.Vita=Information[0].Vita;
                                this.originalAct = Information[0].FgActBerth;
                                this.formMuelle.FgOperativo=Information[0].FgOper;
                                this.formMuelle.RutaMuelle =Information[0].BerthRoute;
                                this.formMuelle.FgActMuelle=Information[0].FgActBerth;
                                this.Image_original = icon;
                                this.dockVal = true;
                                this.$v.$touch();
                               this.$store.state.muelle.apiStateForm = ENUM.INIT;
                            }else{
                                this.$store.state.muelle.apiStateForm = ENUM.ERROR;
                                this.$store.commit('muelle/mutationModal', false);
                            }
                        }else{
                            this.$store.state.muelle.apiStateForm = ENUM.ERROR;
                            this.$store.commit('muelle/mutationModal', false);
                        }
                    }).catch(err => {
                        this.$store.commit('muelle/messageMutation', err);
                        this.$store.state.muelle.apiStateForm = ENUM.ERROR;
                        this.$store.commit('muelle/mutationModal', false);
                    });
                }catch(error){
                    this.$store.commit('muelle/messageMutation', err);
                    this.$store.state.muelle.apiStateForm = ENUM.ERROR;
                    this.$store.commit('muelle/mutationModal', false);
                }
            }
            
        }
    }
    function selectOptions(){
        return [
                { 
                    value: true, 
                    label: this.$t('label.ACTIVO')
                },
                { 
                    value: false, 
                    label: this.$t('label.INACTIVO')
                }
            ];
    }
    function selectOptionsOperativo(){
        return[
            { 
                value: true, 
                label: 'SI'
            },
            { 
                value: false, 
                label: 'NO'
            }
        ];
    }

    export default {
        name: 'app-modal-muelle',
        data,
        mounted,
        mixins: [TipoGruaMixin, ModalMixin],
        validations(){ return FormMuelle() },
        components: {
            PictureInput
        },
        computed: { 
            optionsListPuertos,
            optionsDataPuertos() {
                return this.optionsListPuertos
            },
            idIndentification,
            apiStateLoading,
            apiStateFormLoading,
            selectOptions,
            selectOptionsOperativo,
            //tituloModal,
            ...mapState({
                idState: state=> state.muelle.id,
                modalMuelle: state=> state.muelle.modalMuelle,
                myDataPuerto : state=> state.muelle.myDataPuerto,
                apiState: state => state.muelle.apiState,
                apiStateForm: state => state.muelle.apiStateForm,
                BerthRoute: (state) => state.muelle.BerthRoute,
            })
        },
        directives: UpperCase,
        methods:{
            toKebabCase,
            cerrarModal,
            registerData,
            cambio,
            fuseSearch,
            handleFileUpload,
            isPortValid
        },
        watch:{
            modalMuelle,
            idState
        }
    }
</script>

<style lang="scss">
    

    #c-image{
        width:95%;
        max-width:600px;
        position:relative;
        overflow:hidden;
        text-align:center;
        cursor:pointer;
        display: flex;

        &:before, &:after{
          content:"";
          width: calc(100% - 30px);
          height:4px;
          background:#FFF;
          transition:all .3s;
        }

        &:before{ 
          position: absolute;
          top:335px; 
          left:20px;
          content:""; 
          width:0%; 
          height:3px; 
          background:#fff;
          transition:all .3s ease;
        } 

        &:after{
          left:100%;
          bottom:10px
        }

        img{
          height:200px; 
          width:100%;
        }

        .text{
          text-align:center;
          position:absolute;
          transition:all .3s;
          
          transform:scale(1.5);
          opacity:0;
          
          h1{
            background: #AAA3A2;
            border: 2px solid #BEB9B8;
            border-radius: 5px;
            color:#FFF;
            padding:10px 20px;
          }
        }
        &:hover .text{
          transform:scale(1);
          opacity:1;
        }
    }
    .card-simple{
        border: 0px;
        margin-bottom: 0 !important;
    }
    .text-invalid-feedback {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #e55353;
    }
</style>
